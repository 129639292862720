import { IResponsavelUsina } from "../../../../../models/Gerador/Cadastro/ResponsavelUsina";
import { AbstractValidation, Validate } from "../../../../../utils/AbstractValidation";

export class ResponsavelUsinaValidate extends AbstractValidation<IResponsavelUsina>{
    getValidate(): Validate<IResponsavelUsina>[] {
        return[
            {
                field: "nomeresponsavelusina",
                validate(value, model:IResponsavelUsina, addError: (message: string) => void) {
                    if(!value) addError("O nome deve ser informado")
                },
            },
            {
                field: "emailresponsavelusina",
                validate(value, model:IResponsavelUsina, addError: (message: string) => void) {
                    if(!value) addError("O Email deve ser informado")
                },
            },
            {
                field: "emailresponsavelusina",
                validate(value, model:IResponsavelUsina, addError: (message: string) => void) {
                    if(!value?.includes("@")) addError("Formato de Email inválido")
                },
            },
            {
                field: "celularresponsavelusina",
                validate(value, model:IResponsavelUsina, addError: (message: string) => void) {
                    if(!value) addError("O celular deve ser informado")
                },
            },
            {
                field: "celularresponsavelusina",
                validate(value, model:IResponsavelUsina, addError: (message: string) => void) {
                    if(value?.length !== 11) addError("O celular deve conter 11 dígitos")
                },
            },
            {
                field: "cpfresponsavelusina",
                validate(value, model:IResponsavelUsina, addError: (message: string) => void) {
                    if(!value) addError("O Cpf deve ser informado")
                },
            },
            {
                field: "cpfresponsavelusina",
                validate(value, model:IResponsavelUsina, addError: (message: string) => void) {
                    if(value?.length !== 11) addError("O CPF deve conter 11 dígitos")
                },
            },
            {
                field: "datanascimentoresponsavelusina",
                validate(value, model:IResponsavelUsina, addError: (message: string) => void) {
                    if(!value) addError("A Data de Nascimento deve ser informado")
                },
            },
            {
                field: "datanascimentoresponsavelusina",
                validate(value, model:IResponsavelUsina, addError: (message: string) => void) {
                    const now = new Date();
                    const limitDate = new Date(now.getFullYear() - 18, now.getMonth(), now.getDate());
                    if(value && (new Date(value) > limitDate)) addError("A idade deve ser de pelo menos 18 anos.")
                },
            },
            {
                field: "cepresponsavelusina",
                validate(value, model:IResponsavelUsina, addError: (message: string) => void) {
                    if(!value) addError("O Cep deve ser informado")
                },
            },
            {
                field: "cepresponsavelusina",
                validate(value, model:IResponsavelUsina, addError: (message: string) => void) {
                    if(value?.length !== 8) addError("O cep deve ser conter 8 dígitos")
                },
            }
        ];
    }
}