import { Autocomplete, MenuItem, TextField, Tooltip, Typography, } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid";
import React, { Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState } from "react";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import * as D from "../../../styles/appComponents";
import * as C from "../Gerador/style";
import Datatable from "../../../components/Datatable";
import SkeletonDefaultPage from "../../../components/SkeletonLoads/DefaultPage";
import { toastMessage } from "../../../utils/toastMessage";
import {
  GetDashboardOrdemPagamento,
  GetOrdemPagamentoReport,
  GetOrdensPagamento
} from "../../../services/api/OrdemPagamentoService";
import conta from "../../../assets/Plataforma/conta.svg";
import { AxiosResponse } from "axios";
import { IPaginatedList } from "../../../models/PaginatedList";
import { EnumStatusOrdemPagamento, enumStatusOrdemPagamentoMap } from "../../../enums/EnumStatusOrdemPagamento";
import { ColorType } from "../../../types/ColorType";
import { EnumMeses } from "../../../enums/EnumMeses";
import ExcelIcon from "../../../assets/Plataforma/excelIcon.svg";
import { GetTodasUsinas } from "../../../services/api/GeradorService";
import { IDashboardOrdemPagamento, INovaOrdem, IOrdemPagamento, IReportOrdemPagamento } from "../../../models/OrdemPagamento";
import { IBuscaTodasUsinasPorNome } from "../../../models/TarifaFioB";
import { ReportOrdemPagamento } from "../../../enums/EnumOrdemPagamento";
import { ListView } from "../../../components/ListView";
import { useAuth } from "../../../hooks/useAuth";
import { StatusDashboard } from "../../../models/StatusDashboard";
import { NewOrdemModal } from "./Cadastro/Modais/NewOrdemModal";
import { KWH, REAL } from "../../../utils/masInputs";
import { formatValues } from "../../../utils/moneyFormatter";
import { Dayjs } from "dayjs";
import { HandleDataStatus } from "../../../utils/HandleDataStatus";
import { Support } from "./Support";
import { InputFilterModel } from "../../../models/InputFilterModel";


const OrdemPagamento = () => {
  //region Variáveis
  const [loading, setLoading]: (boolean | Dispatch<SetStateAction<boolean>>)[] = useState<boolean>(true);
  const [ordemDePagamentos, setOrdemDePagamentos]: (IOrdemPagamento[] | Dispatch<SetStateAction<IOrdemPagamento[]>>)[] = useState<IOrdemPagamento[]>([]);
  const [dashboardOrdemDePagamento, setDashboardOrdemDePagamento] = useState<StatusDashboard<EnumStatusOrdemPagamento>[]>([]);
  const [reportOrdemPagamento, setReportOrdemPagamento] = useState<IReportOrdemPagamento>({
    statusContrato: undefined,
    gerador: undefined,
    dataInicial: undefined,
    dataFinal: undefined,
  })
  const [isMobile, setIsMobile]: (boolean | Dispatch<SetStateAction<boolean>>)[] = useState(window.innerWidth < 1024);
  const [page, setPage]: (number | Dispatch<SetStateAction<number>>)[] = useState(1);
  const [usinas, setUsinas] = useState<IBuscaTodasUsinasPorNome[]>([]);
  const [filterStatus, setFilterStatus] = useState<EnumStatusOrdemPagamento | null | undefined | EnumStatusOrdemPagamento>(null)
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [ordemId, setOrdemId] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [statusLoading, setStatusLoading] = useState<{sectionName:string, loading:boolean}>({ sectionName:"ordemPagamento", loading:false })
  const [modelFilter, setModelFilter] = useState<InputFilterModel | null>(null);
  const navigate: NavigateFunction = useNavigate();
  const auth = useAuth()

  const geradorId: string | null = useMemo(() => auth?.user?.idGerador || null, [auth]);
  const [columns, setColumns] = useState<GridColDef[]>([]);

  const dataFormModel:INovaOrdem = {
    id: null,
    dataReferencia: null,
    instalacao: null,
    sigla: null,
    descontoTarifa: null,
    energiaInjetada: null,
    energiaConsumida: null,
    assunto: null,
    descricao: null,
    tarifaDistribuidora: null,
    tipoConexao: null,
    dataLimiteQuitacao: null,
    status: null,
    comprovante: null,
    usinaId: null,
    tarifaBase: null,
    valorBase: null,
    valorCredito: null,
    tarifaFioB: null,
    saldoTotal: null,
    saldo: null,
    active: null,
  }

  const [dataForm, setDataForm] = useState<INovaOrdem>(dataFormModel);

  //endregion

  //region Services
  const loadData = async (model:InputFilterModel | null, type:"clear"|"filter") => {
    setStatusLoading({ sectionName:"ordemPagamento", loading:true });
    setModelFilter(model);

    try {
      const { data }: AxiosResponse<IPaginatedList<IOrdemPagamento>, any> = await GetOrdensPagamento(
        (model?.id? parseInt(model?.id): null),
        model?.instalacao,
        model?.name,
        model?.date,
        page,
        pageSize,
        filterStatus,
        geradorId
      );

      setTotalPages(data?.totalPages);

      setOrdemDePagamentos(() => {
        setStatusLoading({ sectionName:"ordemPagamento", loading:false });

        if (geradorId) return data?.data.filter(x => x.visualizacao);
        else return data?.data;

      });


    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar as ordens de pagamento");
      setStatusLoading({ sectionName:"ordemPagamento", loading:false });

    } finally {
      setLoading(false);
      setStatusLoading({ sectionName:"ordemPagamento", loading:false });
    }
  }

  const getOrdemPagamentos: (queryString?: string) => Promise<void> = async (queryString?: string): Promise<void> => {
    setStatusLoading({ sectionName:"ordemPagamento", loading:true });

    try {
      const { data }: AxiosResponse<IPaginatedList<IOrdemPagamento>, any> = await GetOrdensPagamento(
        (modelFilter?.id? parseInt(modelFilter?.id): null),
        modelFilter?.instalacao,
        modelFilter?.name,
        modelFilter?.date,
        page, 
        pageSize, 
        filterStatus,
        geradorId
      );

      setTotalPages(data?.totalPages);
      setOrdemDePagamentos(() => {
        if (geradorId) return data?.data.filter(x => x.visualizacao);
        else return data?.data;

      });

      setStatusLoading({ sectionName:"ordemPagamento", loading:false });

    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar as ordens de pagamento");
      setStatusLoading({ sectionName:"ordemPagamento", loading:false });

    } finally {
      setLoading(false);
      setStatusLoading({ sectionName:"ordemPagamento", loading:false });
    }
  }

  const getDashboardOrdemPagamentos: () => Promise<void> = async (): Promise<void> => {
    try {
      const { data }: AxiosResponse<any, IDashboardOrdemPagamento[]> = await GetDashboardOrdemPagamento(geradorId);
      setDashboardOrdemDePagamento(data);

    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar o dashboard");
    }
  }

  const getUsinas: () => Promise<void> = async (): Promise<void> => {
    try {
      const response: AxiosResponse<any, IBuscaTodasUsinasPorNome> = await GetTodasUsinas();
      setUsinas(response.data);
    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar as usinas");
    }
  }

  const geraRelatorio: () => Promise<void> = async () => {
    try {
      const response = await GetOrdemPagamentoReport(reportOrdemPagamento);

      const blob: File = response.data;

      const link: HTMLAnchorElement = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `ordemPagamento_${new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/[-:T]/g, "")}.xlsx`;
      link.click();

      URL.revokeObjectURL(link.href);
    } catch (e) {
      toastMessage("error", "Erro ao tentar criar o relatório.");
    }
  }
  //endregion

  //region UI

  useEffect(() => {
    const columnsNoGeradorAccess: GridColDef[] = [
      {
        field: "idReferencial",
        headerName: "Id",
        description:"id",
        flex: 1,
        renderCell: (params: GridRenderCellParams<any, any, any>) => (
          <D.GridField $alignCenter={true}>
            {params.value}
          </D.GridField>
        )
      },
      {
        field: "referente",
        headerName: "Período",
        description:"date",
        flex: 1,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<any, any, any>) => {
          return <D.GridField $alignCenter={true}>
            {EnumMeses[params.row.mes as number] ?? "Mês"}/{params.row.ano == "0" ? "Ano" : params.row.ano}
          </D.GridField>
        }
      },
      {
        field: "instalacao",
        headerName: "Instalação",
        description: "instalacao",
        flex: 1,
        minWidth: 90,
        renderCell: (params: GridRenderCellParams<any, any, any>) => (
          <D.GridField $alignCenter={true}>
            {params.value}
          </D.GridField>
        )
      },
      {
        field: "sigla",
        headerName: "sigla",
        flex: 1,
        renderCell: (params: GridRenderCellParams<any, any, any>) => (
          <D.GridField $alignCenter={true}>
            <Tooltip
              title={params?.row?.nomeUsina}
              arrow
            >
              {params.value}
            </Tooltip>

          </D.GridField>
        )
      },
      {
        field: "kwhInjetado",
        headerName: "Kwh injetado",
        description: "kwh",
        flex: 1,
        minWidth: 110,
        renderCell: (params: GridRenderCellParams<any, any, any>) => (
          <D.GridField>
            <Tooltip
              title={`${params?.value? new KWH().execute(params?.value): "0,00"} KWH` as any}
              arrow
            >
              {`${params?.value? new KWH().execute(params?.value): "0,00"} KWH` as any}
            </Tooltip>
          </D.GridField>
        )
      },
      {
        field: "percentualDescontoTarifa",
        headerName: "Desc tarifa",
        description:"percentage",
        flex: 1,
        minWidth: 110,
        renderCell(params: GridRenderCellParams<any, any, any>) {
          return (
            <D.GridField>
              {params.value ?? "00,00%"}
            </D.GridField>
          );
        },
      },
      {
        field: "valorCredito",
        headerName: "Valor",
        description: "valor",
        flex: 2,
        minWidth: 120,
        renderCell: (params: GridRenderCellParams<any, any, any>) => (
          <D.GridField
          >
            <Tooltip
              title={formatValues(params?.value) as any ?? "R$ 0,00"}
              arrow
            >
              {formatValues(params?.value) as any ?? "R$ 0,00"}
            </Tooltip>
          </D.GridField>
        )
      },
      {
        field: "saldoDeQuitacao",
        headerName: "A Pagar",
        flex: 2,
        minWidth: 120,
        renderCell(params: GridRenderCellParams<any, any, any>) {
          return (
            <D.GridField
            >
              <Tooltip
                title={formatValues(params?.value) as any ?? "R$ 0,00"}
                arrow
              >
                <div style={{ color:`${params?.value < 0? "red": {}}` }}>{formatValues(params?.value) as any ?? "R$ 0,00"}</div>
              </Tooltip>
            </D.GridField>
          );
        },
      },
      {
        field: "conta",
        headerName: "Conta",
        flex: 2,
        renderCell: (params: GridRenderCellParams<any, any, any>) => (
          <D.GridField
            $alignCenter
            onClick={() => navigate(`/Cadastro/OrdemPagamento/conta/${params?.row?.id}`)}
          >
            <D.GridImage src={conta} alt="conta" />
          </D.GridField>
        )
      },
      {
        field: "status",
        headerName: "Status",
        flex: 2,
        renderCell: (params: GridRenderCellParams<any, any, any>) => (
          <D.GridField $alignCenter={true}>
            <Tooltip
              title={HandleDataStatus.getNameStatus(
                params?.row?.status,
                enumStatusOrdemPagamentoMap
              )}
              arrow
            >
              <D.Circle 
                color={HandleDataStatus.getColorStatus<EnumStatusOrdemPagamento>(params?.value, enumStatusOrdemPagamentoMap)} 
              />
            </Tooltip>
          </D.GridField>
        ),
      },
      {
        field: "outras",
        headerName: "Outras",
        flex: 2,
        renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
          <D.GridField $alignCenter={true}>
            <D.DefaultGridButton
              onClick={() => handleOpenModal(params?.row?.id)}>
              Editar
            </D.DefaultGridButton>
          </D.GridField>
        )
      },
    ];

    const columnsGeradorAccess: GridColDef[] = [
      {
        field: "idReferencial",
        headerName: "Id",
        description:"id",
        flex: 1,
        maxWidth: 100,
        renderCell: (params: GridRenderCellParams<any, any, any>) => (
          <D.GridField
          >
            {params.value}
          </D.GridField>
        )
      },
      {
        field: "referente",
        headerName: "Período",
        description:"date",
        flex: 1,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<any, any, any>) => {
          return <D.GridField>
            {EnumMeses[params.row.mes as number] ?? "Mês"}/{params.row.ano == "0" ? "Ano" : params.row.ano}
          </D.GridField>
        }
      },
      {
        field: "instalacao",
        headerName: "Instalação",
        description:"instalacao",
        flex: 1,
        minWidth: 90,
        renderCell: (params: GridRenderCellParams<any, any, any>) => (
          <D.GridField>
            {params.value}
          </D.GridField>
        )
      },
      {
        field: "kwhInjetado",
        headerName: "Kwh injetado",
        flex: 1,
        minWidth: 110,
        renderCell: (params: GridRenderCellParams<any, any, any>) => (
          <D.GridField>
            <Tooltip
              title={`${params?.value? new KWH().execute(params?.value): "0,00"} KWH` as any}
              arrow
            >
              {`${params?.value? new KWH().execute(params?.value): "0,00"} KWH` as any}
            </Tooltip>
          </D.GridField>
        )
      },
      {
        field: "valorCredito",
        headerName: "Valor",
        flex: 1,
        renderCell: (params: GridRenderCellParams<any, any, any>) => (
          <D.GridField>
            <Tooltip
              title={formatValues(params?.value) as any ?? "R$ 0,00"}
              arrow
            >
              {formatValues(params?.value) as any ?? "R$ 0,00"}
            </Tooltip>
          </D.GridField>
        )
      },
      {
        field: "conta",
        headerName: "Conta",
        flex: 1,
        renderCell: (params: GridRenderCellParams<any, any, any>) => (
          <D.GridField
            onClick={() => navigate(`/Cadastro/OrdemPagamento/conta/${params?.row?.id}`)}
          >
            <D.GridImage src={conta} alt="conta" />
          </D.GridField>
        )
      },
      {
        field: "status",
        headerName: "Status",
        renderCell: (params: GridRenderCellParams<any, any, any>) => (
          <D.GridField $alignCenter={true}>
            <Tooltip
              title={HandleDataStatus.getNameStatus(
                params?.row?.status,
                enumStatusOrdemPagamentoMap
              )}
              arrow
            >
              <D.Circle 
                color={HandleDataStatus.getColorStatus<EnumStatusOrdemPagamento>(params?.value, enumStatusOrdemPagamentoMap)} 
              />
            </Tooltip>
          </D.GridField>
        ),
      }
    ];

    if (!!geradorId) setColumns(columnsGeradorAccess);
    else setColumns(columnsNoGeradorAccess);

  }, [auth])

  const handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setReportOrdemPagamento((state: IReportOrdemPagamento) => {
      const updatedState = {
        ...state,
        [name]: value,
      };
      return updatedState;
    });
  }

  const handleChangePage: (value: number) => void = (value: number): void => {
    setPage(value);
  };

  const handleChangePageSize: (value: number) => void = (value: number): void => {
    setPageSize(value);
  };

  const handleSearch: (value: string) => Promise<void> = async (value: string): Promise<void> => {
    getOrdemPagamentos(value);
  };

  const handleOpenModal = (id?:string | null) => {
    if(id) 
      setOrdemId(id);

    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOrdemId(null);
    setOpenModal(false);
    setDataForm(dataFormModel);
  }

  useEffect(() => {
    Promise.all([getUsinas(), getDashboardOrdemPagamentos(), getOrdemPagamentos()] as Promise<void>[]);

  }, [geradorId, filterStatus, openModal, pageSize, page])

  useEffect((): () => void => {
    const handleResize: () => void = (): void => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    return (): void => window.removeEventListener("resize", handleResize);
    
  }, [window]);
  //endregion

  return (
    <>
    <Support>
      {
        loading ? (<SkeletonDefaultPage />) : (
          <>
            <C.Container>
              <ListView<EnumStatusOrdemPagamento>
                sectionName="ordemPagamento"
                hasButtonSendClickSign={false}
                hasOtherStatus={false}
                statusContratoBuilder={enumStatusOrdemPagamentoMap}
                data={dashboardOrdemDePagamento}
                hasSecondaryValues={true}
                hasThridValues={true}
                getFilterStatus={setFilterStatus}
                filterStatusContrato={filterStatus}
              >
                <D.DataArea $align="right">
                  <Datatable
                    sectionName="ordemPagamento"
                    handleButton={(): void => handleOpenModal()}
                    hasButton={!(!!geradorId)}
                    titleButton="Inserir nova ordem"
                    columns={columns}
                    rows={ordemDePagamentos}
                    pageNumber={page}
                    pageSize={pageSize}
                    onChangePage={handleChangePage}
                    onChangePageSize={handleChangePageSize}
                    totalPages={totalPages}
                    onSearch={handleSearch}
                    loadData={loadData}
                    hasFilter={true}
                    statusLoading={statusLoading}
                  />
                  <C.LineBreak />
                  {
                    !geradorId &&
                    <>
                      <C.ReportsArea>
                        <C.SelectArea>
                          <C.ReportsLabel>Status</C.ReportsLabel>
                          <C.ReportsSelect
                            onChange={(e) => handleInputChange({
                              target: {
                                name: ReportOrdemPagamento.STATUSCONTRATO,
                                value: e.target.value
                              }
                            } as unknown as React.ChangeEvent<HTMLInputElement>)}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            renderValue={(value) =>
                              typeof value === "string" && value !== "" ? (
                                value
                              ) : (
                                <span style={{ color: "#aaa" }}>Todos os status</span>
                              )
                            }
                          >
                            {enumStatusOrdemPagamentoMap.map((status, i) => (
                              <MenuItem key={i} value={status.value}>
                                {status.name}
                              </MenuItem>
                            ))}
                          </C.ReportsSelect>
                        </C.SelectArea>
                        <C.SelectArea>
                          <C.ReportsLabel>Selecione um gerador</C.ReportsLabel>
                          <Autocomplete
                            onChange={(e, newValue: IBuscaTodasUsinasPorNome | null) => handleInputChange({
                              target: {
                                name: ReportOrdemPagamento.GERADOR,
                                value: newValue?.id as string ?? "",
                              }
                            } as unknown as React.ChangeEvent<HTMLInputElement>)}
                            options={usinas}
                            fullWidth
                            noOptionsText="Nenhum captador encontrado"
                            getOptionLabel={(option) => option.nome}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Todos os geradores"
                                inputProps={{
                                  ...params.inputProps,
                                  "aria-label": "Without label",
                                }}
                              />
                            )}
                            defaultValue={null}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                          />
                        </C.SelectArea>
                        <C.SelectArea>
                          <C.ReportsLabel>Data da movimentação</C.ReportsLabel>
                          <D.DateTextfield
                            name={ReportOrdemPagamento.DATAINICIAL}
                            onChange={handleInputChange}
                            type="date"
                            label="Data início"
                            fullWidth
                          />
                        </C.SelectArea>
                        <C.SelectArea>
                          <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
                          <D.DateTextfield
                            name={ReportOrdemPagamento.DATAFINAL}
                            onChange={handleInputChange}
                            type="date"
                            label="Data fim"
                            fullWidth
                          />
                        </C.SelectArea>
                      </C.ReportsArea>
                      <C.ExportButton
                        onClick={geraRelatorio}
                        variant="contained"
                        startIcon={
                          <img
                            style={{ width: "20px", color: "white", fill: "white" }}
                            src={ExcelIcon}
                            alt="excelIcon"
                          />
                        }
                      >
                        Gerar relatório
                      </C.ExportButton>
                    </>
                  }
                </D.DataArea>
              </ListView>
            </C.Container>
            <NewOrdemModal
              ordemId={ordemId}
              openModal={openModal}
              onCloseModal={handleCloseModal}
              dataForm={dataForm}
              setDataForm={setDataForm}
              dashboard={dashboardOrdemDePagamento}
              ordemPagamento={(ordemDePagamentos || [])?.find(x => x.id === ordemId)}
            />
          </>
        )
      }
      </Support>
    </>
  );
};

export default OrdemPagamento;
