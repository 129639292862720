import { FC, useCallback, useEffect, useState } from "react";
import {
  CloseButton,
  ContainedButton,
  ModalArea,
  ModalCard,
  ModalContainer,
  ModalHeader,
  ModalLine,
} from "../../styles/appComponents";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CircleCheckbox from "../CircleCheckBox";
import {
  EnumStatusContratoParceiro,
  statusContratoConfig,
} from "../../enums/EnumStatusContratoParceiro.enum";
import { ICaptador, ICaptadorListItem } from "../../models/Captador";
import MaskDefaultInput from "../MasDefaultinput/MaskDefaultInput";
import { IUpdateResponsavelComunicacao } from "../../models/ResponsavelComunicacao";
import { ICelularInputReturn } from "../../models/Celular";
import { CodigoTelefonico } from "../../enums/EnumCodigoTelefonico.enum";
import HistoricoCard from "../HistoricoCard";
import { ICreateLog, ILog } from "../../models/Log";
import { CREATE_LOG_DEFAULT } from "../../utils/InicialValues";
import { IBackofficeListItem } from "../../models/Colaborador/Colaborador";
import { InformacaoAssociado } from "../../models/InformacaoAssociado";
import { obterResponavelLegalInicial } from "../../utils/DadosIniciais";
import { IUpdateResponsavelLegal } from "../../models/ResponsavelLegal";
import useValidate from "../../hooks/useValidate";
import {
  InformacaoAssociadoValdiate,
  UpdateResponsavelComunicacaoValidate,
} from "./ModalCaptadorValidate";

interface IModalCaptadorProps {
  captador: ICaptador;
  handleClose: () => void;
  open?: boolean;
  disabled?: boolean;
  criarNovoLog: (novoLogo: ICreateLog) => Promise<boolean>;
  buscarHistorico: (captadorId: string) => Promise<ILog[]>;
  backOffices: IBackofficeListItem[];
  updateInformacaoAssociado: (
    informacaoAssociado: InformacaoAssociado
  ) => Promise<boolean>;
  updateResponsavelLegal: (
    captadorId: string,
    responsaveisLegais: ReadonlyArray<IUpdateResponsavelLegal>,
    responsavelComunicacao: IUpdateResponsavelComunicacao
  ) => Promise<boolean>;
  captadores: ICaptadorListItem[];
}

const ModalCaptador: FC<IModalCaptadorProps> = ({
  captador,
  open = true,
  handleClose,
  disabled = false,
  criarNovoLog,
  buscarHistorico,
  backOffices,
  updateInformacaoAssociado,
  updateResponsavelLegal,
  captadores,
}) => {
  const [editar, setEditar] = useState<boolean>(false);
  const [status, setStatus] = useState<EnumStatusContratoParceiro | null>(
    captador.statusContrato
  );
  const [historico, setHistorico] = useState<ILog[]>([]);
  const [novoHistorico, setNovoHistorico] =
    useState<ICreateLog>(CREATE_LOG_DEFAULT);

  const [responsavelComunicacao, setResponsavelComunicacao] =
    useState<IUpdateResponsavelComunicacao>({
      nome: captador.responsavelComunicacao?.nome ?? "",
      email: captador.responsavelComunicacao?.email ?? "",
      celular: captador.responsavelComunicacao?.celular ?? "",
      countryCode:
        captador.responsavelComunicacao?.countryCode ?? CodigoTelefonico.BRASIL,
    });

  const [informacaoAssociado, setInformacaoAssociado] =
    useState<InformacaoAssociado>({
      captadorId: captador.id,
      tipoParceiro: captador.tipoParceiro,
      convenio: captador.enumConvenio,
      backOfficeId: captador.backofficeId ?? "",
      captadorMasterId: captador.parceiroMasterId ?? "",
    });

  const updateResponsavelComunicacaoValidate = useValidate(
    UpdateResponsavelComunicacaoValidate
  );

  const informacaoAssociadoValdiate = useValidate(InformacaoAssociadoValdiate);

  const handleNewHistoricoChange = async (
    assunto: string,
    descricao: string,
    file: File | null
  ) => {
    setNovoHistorico({
      ...novoHistorico,
      assunto,
      descricao,
      anexo: file,
    });
  };

  const buscarDados = useCallback(
    async (captadorId: string) => {
      if (captadorId !== "") {
        const novoHistorico = await buscarHistorico(captadorId);
        setHistorico(novoHistorico);
      }
    },
    [buscarHistorico]
  );

  const handleCreateNovoLog = async () => {
    const resultado = await criarNovoLog({
      ...novoHistorico,
      captadorId: captador.id,
    });
    if (resultado) await buscarDados(captador.id);
  };

  const handleSalvar = async () => {
    await Promise.all([
      updateInformacaoAssociado(informacaoAssociado),
      updateResponsavelLegal(
        captador.id,
        obterResponavelLegalInicial(captador),
        responsavelComunicacao
      ),
    ]);
    setEditar(false);
  };

  useEffect(() => {
    buscarDados(captador.id);
  }, [captador.id, buscarDados]);

  return (
    <Modal open={open}>
      <ModalContainer>
        <ModalArea>
          <ModalCard>
            <ModalHeader>
              <h2>{`${captador.idReferencial} - ${captador.razaoSocial} - ${captador.cnpj}`}</h2>
              <CloseButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </CloseButton>
            </ModalHeader>
            <ModalLine>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>Status atual</Typography>
                </Grid>
                <Grid item xs={12} container spacing={2}>
                  {Object.entries(statusContratoConfig).map(([key, config]) => (
                    <Grid item key={key}>
                      <CircleCheckbox
                        label={config.label}
                        color={config.color}
                        key={key}
                        id={config.id}
                        selected={status === key}
                        onSelect={() =>
                          setStatus(key as EnumStatusContratoParceiro)
                        }
                        disabled
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Nome"
                      required
                      fullWidth
                      value={captador.razaoSocial}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MaskDefaultInput
                      label="Celular"
                      type="CELULAR"
                      value={responsavelComunicacao.celular}
                      valueCountryCode={responsavelComunicacao.countryCode}
                      disabled={disabled || !editar}
                      onChange={(x) => {
                        const celular = x as ICelularInputReturn;
                        setResponsavelComunicacao({
                          ...responsavelComunicacao,
                          celular: celular.value,
                          countryCode: celular.zipCode,
                        });
                      }}
                      {...updateResponsavelComunicacaoValidate.addValidateCampo(
                        "celular",
                        responsavelComunicacao
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="E-mail"
                      fullWidth
                      type="email"
                      value={responsavelComunicacao.email}
                      disabled={disabled || !editar}
                      onChange={(x) =>
                        setResponsavelComunicacao({
                          ...responsavelComunicacao,
                          email: x.target.value,
                        })
                      }
                      {...updateResponsavelComunicacaoValidate.addValidateCampo(
                        "email",
                        responsavelComunicacao
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Parceiro"
                      required
                      fullWidth
                      value={
                        captadores.find(
                          (c) => c.id === captador.parceiroMasterId
                        )?.nome
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      options={backOffices}
                      fullWidth
                      noOptionsText="Nenhum backoffice encontrado"
                      getOptionLabel={(option) => option.nome}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Backoffice"
                          {...informacaoAssociadoValdiate.addValidateCampo(
                            "backOfficeId",
                            informacaoAssociado
                          )}
                        />
                      )}
                      onChange={(_, newValue) =>
                        setInformacaoAssociado({
                          ...informacaoAssociado,
                          backOfficeId: newValue?.id ?? "",
                        })
                      }
                      value={
                        backOffices.find(
                          (x) => x.id === informacaoAssociado.backOfficeId
                        ) ?? null
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      disabled={disabled || !editar}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2} justifyContent="end">
                  <Grid item>
                    <ContainedButton
                      $color="orange"
                      disabled={disabled || editar}
                      onClick={() => setEditar(true)}
                    >
                      EDITAR
                    </ContainedButton>
                  </Grid>
                  <Grid item>
                    <ContainedButton
                      $color="pink"
                      disabled={
                        disabled ||
                        updateResponsavelComunicacaoValidate.error ||
                        informacaoAssociadoValdiate.error ||
                        !editar
                      }
                      onClick={handleSalvar}
                    >
                      SALVAR
                    </ContainedButton>
                  </Grid>
                </Grid>
              </Grid>
            </ModalLine>
          </ModalCard>
          <HistoricoCard
            tableData={historico}
            saveFunction={handleCreateNovoLog}
            getNewHistoricData={handleNewHistoricoChange}
            reloadTable={() => buscarDados(captador.id)}
          />
        </ModalArea>
      </ModalContainer>
    </Modal>
  );
};

export default ModalCaptador;
