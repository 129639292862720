import {
  IContratoData,
  ICreateGDContratoCliente,
  IFullGDContratoCliente,
  IGDContrato,
  IGDContratoPaged,
  IUpdateEndereco,
  IUpdateInformacoesContrato,
  IUpdateInformacoesTitularPF,
  IUpdateInformacoesTitularPJ,
  IUpdateOutrasInformacoes,
  IUpdatePreferencias,
  IUpdateResponsavelLegal,
} from "../../models/GDContrato";
import { IPaginatedList } from "../../models/PaginatedList";
import http from "../common/http-common";
import { StatusDashboard } from "../../models/StatusDashboard";
import { EnumStatusContratoCliente } from "../../enums/EnumStatusContratoCliente.enum";
import { EnumStatusGDProposta } from "../../enums/EnumStatusGDProposta.enum";
import { AxiosResponse } from "axios";
import {
  IDashboardExtratoPorUsina,
  IExtratoPorUsina,
} from "../../pages/private/ExtratoPorUsina";
import { IContratoReport } from "../../pages/private/Contratos";

export const CreateGDContrato = (data: ICreateGDContratoCliente) => {
  return http.post("/GDContratoCliente", data);
};

//#region - Update

export const UpdateResponsavelLegal = (data: IUpdateResponsavelLegal) => {
  return http.put("/GDContratoCliente/PessoaJuridica/ResponsavelLegal", data);
};

export const UpdateEndereco = (data: IUpdateEndereco) => {
  return http.put("/GDContratoCliente/PessoaFisica/Endereco", data);
};

export const UpdateInformacoesTitularPF = (
  data: IUpdateInformacoesTitularPF
) => {
  return http.put("/GDContratoCliente/PessoaFisica/InformacoesTitular", data);
};

export const UpdateInformacoesTitularPJ = (
  data: IUpdateInformacoesTitularPJ
) => {
  return http.put("/GDContratoCliente/PessoaJuridica/InformacoesTitular", data);
};

export const UpdateInformacoesContrato = (data: IUpdateInformacoesContrato) => {
  return http.put("/GDContratoCliente/InformacoesContrato", data);
};

export const UpdateOutrasInformacoes = (data: IUpdateOutrasInformacoes) => {
  return http.put("/GDContratoCliente/OutrasInformacoes", data);
};

export const UpdatePreferencias = (data: IUpdatePreferencias) => {
  return http.put("/GDContratoCliente/Preferencias", data);
};
//#endregion

//#region - Querys

//#region - GDContrato
export const EnviaContratoParaClickSign = (id: string) => {
  return http.post(
    `/GDContratoCliente/EnviaContratoParaAssinaturaDigital/${id}`
  );
};

export const VerificaPodeEnviarContratoParaClickSign = (id: string) => {
  return http.get<boolean>(
    `/GDContratoCliente/VerifyCanCreateIndividualSignature/${id}`
  );
};

export const GetStats: () => Promise<
  AxiosResponse<StatusDashboard<EnumStatusContratoCliente>[]>
> = () => {
  return http.get<StatusDashboard<EnumStatusContratoCliente>[]>(
    "/GDContratoCliente/Stats"
  );
};

export const GetContratos = (
  id: number | null,
  instalacao: string | null | undefined,
  name: string | null | undefined,
  date: string | null | undefined,
  pageNumber: number,
  pageSize: number,
  search?: string,
  filterStatusContratoCliente?: EnumStatusContratoCliente | null
) => {
  var searchString: string = "";
  let url = "/GDContratoCliente";
  const params = [];

  if (id) params.push(`Id=${id}`);
  if (instalacao) params.push(`Instalacao=${instalacao}`);
  if (name) params.push(`Name=${name}`);
  if (date) params.push(`Date=${date}`);
  if (pageSize) params.push(`PageSize=${pageSize}`);
  if (pageNumber) params.push(`PageNumber=${pageNumber}`);
  if (search) params.push(`Search=${search}`);
  if (filterStatusContratoCliente !== null)
    params.push(`StatusContratoCliente=${filterStatusContratoCliente}`);

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  return http.get<IPaginatedList<IGDContratoPaged>>(url);
};

export const GetContratoById = (id: string) => {
  return http.get<IGDContrato>(`/GDContratoCliente/${id}`);
};

export const GetContratoDataByPropostaId = (id: string, tipoPessoa: 0 | 1) => {
  return http.get<IContratoData>(
    `/GDContratoCliente/GetContratoDataByPropostaId/?Id=${id}&TipoPessoa=${tipoPessoa}`
  );
};

export const GetFullContratoById = (id: string) => {
  return http.get<IFullGDContratoCliente>(
    `/GDContratoCliente/GetFullGDContratoCliente/${id}`
  );
};

export const gerarRelatorio: (
  filters: IContratoReport
) => Promise<AxiosResponse<Blob>> = async (filters: IContratoReport) => {
  const queryParams: string[] = [];

  if (filters.EnumStatusContratoCliente) {
    queryParams.push(
      `EnumStatusContratoCliente=${filters.EnumStatusContratoCliente}`
    );
  }
  if (filters.CaptadorId) {
    queryParams.push(`CaptadorId=${filters.CaptadorId}`);
  }
  if (filters.BackofficeId) {
    queryParams.push(`BackofficeId=${filters.BackofficeId}`);
  }
  if (filters.dataInicio) {
    queryParams.push(`dataInicio=${filters.dataInicio}`);
  }
  if (filters.dataFim) {
    queryParams.push(`dataFim=${filters.dataFim}`);
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

  return http.get<File>(
    `/GDContratoCliente/BuscaRelatorioContratos${queryString}`,
    {
      responseType: "blob",
    }
  );
};
//#endregion

//#region Extrato por usina
export const GetExtratoDeUsinas = (
  pageNumber: number,
  pageSize: number,
  search?: string,
  filterStatusContratoCliente?: EnumStatusContratoCliente | null
) => {
  var searchString: string = "";
  let url = "/GDContratoCliente/GetRelatorioPorUsina";
  const params = [];

  if (pageSize) params.push(`PageSize=${pageSize}`);
  if (pageNumber) params.push(`PageNumber=${pageNumber}`);
  if (search) params.push(`Search=${search}`);
  if (filterStatusContratoCliente !== null)
    params.push(`StatusContratoCliente=${filterStatusContratoCliente}`);

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  return http.get<IPaginatedList<IExtratoPorUsina>>(url);
};

export const GetDashboardExtratoDeUsinas: () => Promise<
  AxiosResponse<IDashboardExtratoPorUsina>
> = () => {
  return http.get<IDashboardExtratoPorUsina>(
    `/GDContratoCliente/GetDasboardRelatorioPorUsinas`
  );
};

export const GerarRelatorioExtratoDeUsinas = (
  usinaId: string
): Promise<AxiosResponse<File>> => {
  const url = "/GDContratoCliente/BuscaRelatorioExtratPorUsinas";
  const params = new URLSearchParams();

  if (usinaId) {
    params.append("UsinaId", usinaId);
  }

  return http.get<File>(`${url}?${params.toString()}`, {
    responseType: "blob",
  });
};

//#endregion

//#endregion
